require('./bootstrap');

import 'bootstrap';

document.addEventListener("DOMContentLoaded", function() {
    document.querySelector('.lang_switcher').onclick = e=>{
        e.preventDefault();
        let other_langs_elements = document.querySelector('.other_langs');
        ['visible'].map(v=> other_langs_elements.classList.toggle(v) )
    };

});
